@charset "utf-8";

@import "fundraise";

#backdrop-xs {
  background-image: url("/images/small.jpg");
}

@media only screen and (min-width: 48em) {
  #backdrop {
    background-image: url("/images/large.jpg");
  }
  // #logo {
  //   background: rgba(255,255,255,.8);
  // }
}

